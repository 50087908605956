
const URL_PRODUCTION  = process.env.REACT_APP_URL_PRODUCTION || "https://hutoryanin.ru/"
const URL_TEST  = process.env.REACT_APP_URL_TEST || "https://hutorit.ru/" 
const URL_DEVELOPMENT  = process.env.REACT_APP_URL_DEVELOPMENT || "http://localhost:3000/"

const API_URL_PRODUCTION = process.env.REACT_APP_API_URL_PRODUCTION || "https://server.hutoryanin.ru/"
const API_URL_TEST = process.env.REACT_APP_API_URL_TEST || "https://server.hutorit.ru/"
const API_URL_DEVELOPMENT = process.env.REACT_APP_API_URL_DEVELOPMENT //|| "http://localhost:5000/"

export const URL = 
    process.env.REACT_APP_ENV === 'production' 
    ? window.location.hostname === "hutoryanin.ru" || window.location.hostname === "www.hutoryanin.ru"
        ? URL_PRODUCTION 
        : URL_TEST
    : URL_DEVELOPMENT

export const API_URL = 
    process.env.REACT_APP_ENV === 'production' 
    ? window.location.hostname === "hutoryanin.ru" || window.location.hostname === "www.hutoryanin.ru"
        ? API_URL_PRODUCTION
        : API_URL_TEST
    : API_URL_DEVELOPMENT
        

export const MAIN_ROUTE = '/' // главная

export const ADMIN_ROUTE = '/publication/admin' // администрирование

export const USER_ROUTE = '/user' // пользователь
export const USER_CREATE_ROUTE = '/user/create' // добавление пользователя
export const USER_GET_ALL_ROUTE = '/user/get_all' // список пользователей

export const CATEGORY_ROUTE = '/category' // категории
export const CATEGORY_CREATE_ROUTE = '/category/create' // добавление категории
export const CATEGORY_GET_ALL_ROUTE = '/category/get_all' // список категорий

export const PUBLICATION_ROUTE = '/publication' // публикации
export const PUBLICATION_CREATE_ROUTE = '/publication/create' // добавление публикации
export const PUBLICATION_GET_ALL_ROUTE = '/publication/get_all' // добавление публикации

export const PRODUCT_ROUTE = '/product' // товары
export const PRODUCT_CREATE_ROUTE = '/product/create' // добавление товара
export const PRODUCT_GET_ALL_ROUTE = '/product/get_all' // добавление товара

export const SUPPORT_ROUTE = '/support' // тех.поддержка
export const INFO_ROUTE = '/info' // информация
export const ABOUT_ME_ROUTE = '/info/about_me' // обо мне
export const PROMO_ROUTE = '/promo' // акции
export const LK_ROUTE = '/lk' // личный кабинет
