
import { $host } from './index'

export const getAll = async () => {
    const { data } = await $host.get('api/publication_data/')
    return data
}

export const getAllWherePublicationId = async (publication_id) => {
    const { data } = await $host.get('api/publication_data/where/' + publication_id)
    return data
}

export const create = async (body) => {
    const { data } = await $host.post('api/publication_data/', body)
    return data
}

export const deleteOne = async (id) => {
    const { data } = await $host.delete('api/publication_data/' + id)
    return data
}

export const addImage = async (publication_id, body) => {
    const { data } = await $host.post('api/publication_data/image/' + publication_id, body)
    return data
}

export const edit = async (id, body) => {
    const { data } = await $host.put('api/publication_data/' + id, body)
    return data
}

export const editImage = async (publication_data_id, body) => {
    const { data } = await $host.put('api/publication_data/image/' + publication_data_id, body) 
    return data
}

export const deleteImage = async (publication_data_id, file_name) => {
    const { data } = await $host.delete('api/publication_data/image/' + publication_data_id + "/" + file_name)
    return data
}