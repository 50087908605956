
import { $host } from './index'

export const getAll = async () => {
    const { data } = await $host.get('api/user/')
    return data
}

export const create = async (body) => {
    const { data } = await $host.post('api/user/', body)
    return data
}

export const deleteOne = async (id) => {
    const { data } = await $host.delete('api/user/' + id)
    return data
}
