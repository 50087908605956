// LkPage

import Repair from '../../components/repair/Repair'

import './LkPage.css'


const LkPage = () => {
    return (
        <div 
            className="LkPage globalStylePage"
        >
            <div
                // className="LkPage_title globalStylePage_title"
            >
                <Repair />
            </div>
        </div>
    )
}

export default LkPage
