
import { NavLink } from 'react-router-dom'

import { ADMIN_ROUTE } from '../../utils/consts'
import './ProductPage.css'


const ProductCreatePage = () => {

    return (
        <div 
            className="ProductPage globalStylePage"
        >
            <div
                // className="ProductPage_title globalStylePage_title"
            >
                <br />
                <NavLink
                    className="btn btn-dark"
                    to={ADMIN_ROUTE}
                >
                    Админ панель
                </NavLink>
            </div>
        </div>
    )
}

export default ProductCreatePage 
