
export default function getLink(text) {
    
    let string = text.replace(/\n/g,"<br/>").replace(/(<br \/>)/g,"<br/>")
    
    let text1 = "https://"
    let text2 = "http://"
    let text3 = "<br/>https://"
    let text4 = " https://"
    let text5 = "<br/>http://"
    let text6 = " http://"

    while (string.indexOf(text1) == 0 || string.indexOf(text2) == 0 || string.includes(text3) || string.includes(text4) || string.includes(text5) || string.includes(text6)) {
        if (string.indexOf(text1) == 0) {
            string = getText(string, text1, 0)
        }
        if (string.indexOf(text2) == 0) {
            string = getText(string, text2, 0)
        }
        if (string.includes(text3)) {
            string = getText(string, text3, 5)
        }
        if (string.includes(text4)) {
            string = getText(string, text4, 1)
        }
        if (string.includes(text5)) {
            string = getText(string, text5, 5)
        }
        if (string.includes(text6)) {
            string = getText(string, text6, 1)
        }
    }

    return string
}


// вспомогательная функция
const getText = (string, text, number) => {

    let temp = null
    
    let startIndex = string.indexOf(text)
    let stopIndex = string.indexOf(' ', startIndex + number) // перепрыгиваем пробел
    let tempIndex = string.indexOf('<br/>', startIndex + number)

    if (stopIndex == -1 || (tempIndex != -1 && tempIndex < stopIndex))
        stopIndex = tempIndex

    if (stopIndex == -1) temp = string.substr(startIndex + number)
    else temp = string.substr(startIndex + number, stopIndex - startIndex - number)

    string = string.replaceAt(startIndex + number, stopIndex, temp)
    
    return string
}


String.prototype.replaceAt = function(start, stop, text) {
    let replacement = `<a href="${text}" target="_blank" rel="noopener noreferrer">${text}</a>`
    if (stop == -1) 
        return this.substr(0, start) + replacement
    return this.substr(0, start) + replacement + this.substr(stop)
}