
import { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

import { create } from '../../http/userApi'
import { ADMIN_ROUTE, USER_GET_ALL_ROUTE } from '../../utils/consts'
import './UserPage.css'


const UserCreatePage = () => {

    const navigate  = useNavigate()

    const [ name, setName ] = useState()
    const [ email, setEmail ] = useState()
    const [ password, setPassword ] = useState()
    const [ role, setRole ] = useState()
    const [ full_name, setFull_name ] = useState()
    const [ phone, setPhone ] = useState()
    const [ address, setAddress ] = useState()

    const createUser = () => {
        let data = {
            name, email, password, role, full_name, phone, address
        }
        create(data)
            .then(
                data => {
                    // alert(JSON.stringify(data)
                    if (data.name) {
                        alert(`Пользователь ${data.name} создан`)
                        navigate(USER_GET_ALL_ROUTE)
                    }
                }),
                error => alert(JSON.stringify(error))
            .catch(error => alert(JSON.stringify(error)))
    }


    return (
        <div 
            className="UserPage globalStylePage"
        >
            <div
                // className="UserPage_title globalStylePage_title"
            >
                <br />
                <NavLink
                    className="btn btn-dark"
                    to={ADMIN_ROUTE}
                >
                    Админ панель
                </NavLink>

                <br /><br />

                <center> <h3>Добавление нового пользователя</h3> <br />
                <table>                    
                    <tr>
                        <td>Имя</td>
                        <td>
                            <input 
                                className="inp inp-success" 
                                value={name}
                                onChange={e => setName(e.target.value)}  
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>
                            <input 
                                className="inp inp-success" 
                                value={email}
                                onChange={e => setEmail(e.target.value)}  
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Пароль</td>
                        <td>
                            <input 
                                className="inp inp-success" 
                                value={password}
                                onChange={e => setPassword(e.target.value)}  
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Роль</td>
                        <td>
                            <select 
                                className="inp inp-success"
                                value={role}
                                onChange={e => setRole(e.target.value)}
                            >
                                <option value="USER">Пользователь</option>
                                <option value="ADMIN">Администратор</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>ФИО</td>
                        <td>
                            <input 
                                className="inp inp-success" 
                                value={full_name}
                                onChange={e => setFull_name(e.target.value)}  
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Телефон</td>
                        <td>
                            <input 
                                className="inp inp-success" 
                                value={phone}
                                onChange={e => setPhone(e.target.value)}  
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Адрес</td>
                        <td>
                            <input 
                                className="inp inp-success" 
                                value={address}
                                onChange={e => setAddress(e.target.value)}  
                            />
                        </td>
                    </tr>
                </table>
                </center>

                <br />

                <button
                    className="btn btn-success" 
                    onClick={createUser}
                >
                    Создать
                </button>

                <br /><br />

            </div>
        </div>
    )
}

export default UserCreatePage 
