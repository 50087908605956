
import { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'

import { getAll, deleteOne } from '../../http/categoryApi'
import { ADMIN_ROUTE } from '../../utils/consts'

import './CategoryPage.css'


const CategoryGetAllPage = () => {
    
    const [ categories, setCategories ] = useState([])
    
    useEffect(() => {
        getCategories()
    },[])

    const getCategories = () => {
        getAll().then(data => setCategories(data))
    }

    const deleteCategory = (category) => {
        let yes = confirm(`Вы уверены что хотите удалить категорию ${category.name}?`)
        if (yes) deleteOne(category.id).then(data => {
            if (data) {
                alert("Категория удалена")
                getCategories()
            }
        })
    }



    return (
        <div 
            className="CategoryPage globalStylePage"
        >
            <div
                // className="CategoryPage_title globalStylePage_title"
            >
                <br />
                <NavLink
                    className="btn btn-dark"
                    to={ADMIN_ROUTE}
                >
                    Админ панель
                </NavLink>

                <br /><br />

                <center> <h3>Список всех категорий</h3> <br />
                <table>
                    <tr>
                        <th>#</th>
                        <th>Имя</th>
                        <th>Ссылка</th>
                        <th>Чья</th>
                        <th>Дата создания</th>
                        <th></th>
                    </tr>   
                    {categories && categories[0] &&
                    categories.map(category =>                  
                    <tr>
                        <td>{category.id}</td>
                        <td>{category.name}</td>
                        <td>{category.url}</td>
                        <td>{category.whose}</td>
                        <td>
                            {category.createdAt.split("T")[0].split("-")[2]}
                            .
                            {category.createdAt.split("T")[0].split("-")[1]}
                            .
                            {category.createdAt.split("T")[0].split("-")[0]}
                        </td>
                        <td>
                            <button
                                className="btn btn-danger" 
                                onClick={() => deleteCategory(category)}
                            >
                                Удалить
                            </button>
                        </td>
                    </tr>
                    )}                    
                </table>
                </center>

            </div>
        </div>
    )
}

export default CategoryGetAllPage
