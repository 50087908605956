
import { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'

import { getAll, deleteOne } from '../../http/publicationApi'
import PublicationData from './data/PublicationData'
import { ADMIN_ROUTE } from '../../utils/consts'

import './PublicationPage.css'


const PublicationGetAllPage = () => {

    const [ publications, setPublications ] = useState([])
    const [ publicationData, setPublicationData ] = useState(null)
    
    useEffect(() => {
        getPublications()
    },[])

    const getPublications = () => {
        getAll().then(data => setPublications(data.reverse()))
    }

    const deletePublication = (publication) => {
        let yes = confirm(`Вы уверены что хотите удалить публикацию ${publication.name}?`)
        if (yes) deleteOne(publication.id).then(data => {
            if (data) {
                getPublications()
                alert("Публикация удалена")
            }
        })
    }

    const getPublicationData = (publication) => {
        setPublicationData(publication)
    }


    return (
        <div 
            className="PublicationPage globalStylePage heightBody"
        >
            <div
                // className="PublicationPage_title globalStylePage_title"
            >
                <br />
                <NavLink
                    className="btn btn-dark"
                    to={ADMIN_ROUTE}
                >
                    Админ панель
                </NavLink>

                <br /><br />

                {publicationData 
                ?
                    <PublicationData publication={publicationData} setPublication={setPublicationData} />
                :
                <center> <h3>Список всех публикаций</h3> <br />
                <table>
                    <tbody>
                    <tr>
                        {/* <th>#</th> */}
                        {/* <th>Id пользователя</th> */}
                        {/* <th>Id категории</th> */}
                        <th>Наименование</th>
                        {/* <th>Дата создания</th> */}
                        <th>Данные</th>
                        <th></th>
                    </tr>   
                    {publications && publications[0] &&
                    publications.map((publication, idx) =>                  
                    <tr key={"keyPublicationGetAllPage" + idx}>
                        {/* <td>{publication.id}</td> */}
                        {/* <td>{publication.user_id}</td> */}
                        {/* <td>{publication.category_id}</td> */}
                        <td>{publication.name}</td>
                        {/* <td>
                            {publication.createdAt.split("T")[0].split("-")[2]}
                            .
                            {publication.createdAt.split("T")[0].split("-")[1]}
                            .
                            {publication.createdAt.split("T")[0].split("-")[0]}
                        </td> */}
                        <td>
                            <button
                                className="btn btn-success" 
                                onClick={() => getPublicationData(publication)}
                            >
                                Посмотреть
                            </button>
                        </td>
                        <td>
                            <button
                                className="btn btn-danger" 
                                onClick={() => deletePublication(publication)}
                            >
                                Удалить
                            </button>
                        </td>
                    </tr>
                    )}        
                    </tbody>            
                </table>
                </center>
                }

            </div>
        </div>
    )
}

export default PublicationGetAllPage
