// PromoPage

import Repair from '../../components/repair/Repair'

import './PromoPage.css'


const PromoPage = () => {
    return (
        <div 
            className="PromoPage globalStylePage"
        >
            <div
                // className="PromoPage_title globalStylePage_title"
            >
                <Repair />
            </div>
        </div>
    )
}

export default PromoPage
