
import { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'

import { getAll, deleteOne } from '../../http/userApi'
import { ADMIN_ROUTE } from '../../utils/consts'

import './UserPage.css'


const UserGetAllPage = () => {

    const [ users, setUsers ] = useState([])
    
    useEffect(() => {
        getUsers()
    },[])

    const getUsers = () => {
        getAll().then(data => setUsers(data))
    }

    const deleteUser = (user) => {
        let yes = confirm(`Вы уверены что хотите удалить пользователя ${user.name}?`)
        if (yes) deleteOne(user.id).then(data => {
            if (data) {
                alert("Пользователь удалён")
                getUsers()
            }
        })
    }


    return (
        <div 
            className="UserPage globalStylePage"
        >
            <div
                // className="UserPage_title globalStylePage_title"
            >
                <br />
                <NavLink
                    className="btn btn-dark"
                    to={ADMIN_ROUTE}
                >
                    Админ панель
                </NavLink>

                <br /><br />

                <center> <h3>Список всех пользователей</h3> <br />
                <table>
                    <tr>
                        <th>#</th>
                        <th>Имя</th>
                        <th>Email</th>
                        <th>Роль</th>
                        <th>ФИО</th>
                        <th>Телефон</th>
                        <th>Адрес</th>
                        <th>Дата создания</th>
                        <th></th>
                    </tr>   
                    {users && users[0] &&
                    users.map(user =>                  
                    <tr>
                        <td>{user.id}</td>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.role}</td>
                        <td>{user.full_name}</td>
                        <td>{user.phone}</td>
                        <td>{user.address}</td>
                        <td>
                            {user.createdAt.split("T")[0].split("-")[2]}
                            .
                            {user.createdAt.split("T")[0].split("-")[1]}
                            .
                            {user.createdAt.split("T")[0].split("-")[0]}
                        </td>
                        <td>
                            <button
                                className="btn btn-danger" 
                                onClick={() => deleteUser(user)}
                            >
                                Удалить
                            </button>
                        </td>
                    </tr>
                    )}                    
                </table>
                </center>
                
            </div>
        </div>
    )
}

export default UserGetAllPage 
