
import { useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'

import Admin from '../admin/AdminPage'
import PublicationCreatePage from './PublicationCreatePage'
import PublicationGetAllPage from './PublicationGetAllPage'
import { getAll as getAllPublications } from '../../http/publicationApi'
import { getAllWherePublicationId } from '../../http/publicationDataApi'
import { getAll as getAllCategories } from '../../http/categoryApi'
import { API_URL } from '../../utils/consts'
import getLink from '../../utils/getLink'
import translit from '../../utils/translit'

import './PublicationPage.css'
import Loader from '../../components/loader/Loader'


const PublicationPage = () => {
    
    let { name, publication_name } = useParams()

    const [ loading, setLoading ] = useState(false)
    const [ publications, setPublications ] = useState([])
    const [ publicationData, setPublicationData ] = useState([])
    const [ categories, setCategories ] = useState([])
    
    useEffect(() => {
        getPublications()
    },[])
    
    useEffect(() => {
        if (publications && publication_name) {
            let one = publications.filter(publication => publication.name.toLowerCase() == publication_name)[0]
            // alert("gdfg")
            if (one) {
                setLoading(true)
                getAllWherePublicationId(one.id)
                    .then(data => setPublicationData(data))
                    .finally(() => setLoading(false))
            }

        }
    },[publications, publication_name])

    const getPublications = () => {
        setLoading(true)
        getAllCategories().then(data => setCategories(data))
        getAllPublications()
            .then(data => setPublications(data.reverse()))
            .finally(() => setLoading(false))
    }

    // const onClickView = () => {
    //     getPublications()
    // }

    if (loading) 
        return <Loader className="heightBody" size={"small"} />


    return (
        <div 
            className="PublicationPage globalStylePage heightBody"
        >
            <div
                className="PublicationPage_title"
            >                
                {!name
                ? 
                <center>
                    <h2 className="globalStylePage_title">Список публикаций</h2>

                    <div>
                        {publications && publications[0] && categories && categories[0]
                        ?
                        <table>
                            <tr>
                                {/* <td>#</td> */}
                                <td>Наименование</td>
                                {/* <td>Категория</td> */}
                                <td></td>
                            </tr>
                        {publications.map((publication, idx) => 
                            <tr key={"PublicationPage_publication" + idx}>
                                {/* <td>{publication.id}</td> */}
                                <td>{publication.name}</td>
                                {/* <td>{categories.filter(category => category.id == publication.category_id)[0].name}</td> */}
                                <td>
                                    <NavLink
                                        className="btn btn-primary"
                                        to={"/" + 
                                            categories.filter(category => category.id == publication.category_id)[0].url + 
                                            // "/" + publication.id +
                                            "/" + publication.name.toLowerCase()}
                                        // onClick={onClickView}
                                    >
                                        Посмотреть
                                    </NavLink>
                                </td>
                            </tr>
                        )}
                        </table> 
                        :
                         "Нет найденых публикаций"
                        }
                    </div>
                </center>
                :
                name == "admin" // for ADMIN
                ? 
                    <Admin />
                :
                name == "create" // for ADMIN
                ?
                    <PublicationCreatePage />
                :
                name == "get_all" // for ADMIN
                ?
                    <PublicationGetAllPage />
                :
                <>
                    {publications && publications[0] && publicationData && publicationData[0] 
                    ?
                    publications
                        .filter(publication => publication.name.toLowerCase() == publication_name)
                        .map((publication, idx) => 
                        <div 
                            key={"PublicationPage_pub" + idx}
                            className=''
                        >
                            <h2 className="globalStylePage_title">{publication.name}</h2>
                            <div
                                className='PublicationPage_pub_body_width'
                            >
                                <br />
                                {publicationData.map((pubData, idx) => {
                                    let image = null
                                    if (pubData.image)
                                        image = pubData.image

                                    let data = null
                                    if (pubData.data) {
                                        data = getLink(pubData.data)
                                    }
                                
                                    return <div key={"PublicationPage_pubData" + idx}>
                                        {image && <> <img src={API_URL + "/images/publications/" + translit(publication.name) + "/" + image} alt={publication.name} /> <br /> </> }
                                        {data && <> <div dangerouslySetInnerHTML={{ __html: data }} /> <br /> </>}
                                    </div>
                                })}
                            </div>
                        </div>)
                    :
                        "нет данных"
                    }
                </>
                }
            </div>
        </div>
    )
}

export default PublicationPage
