
import './Loader.css'


const Loader = (props) => {
    return (
        <div
            className={props?.className + " Loader"}
        >
            <span className={props?.size && props.size == "small" ? "Loader_small" : "Loader_normal"} />
        </div>
    )
}

export default Loader
