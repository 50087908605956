
import { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

import { create } from '../../http/categoryApi'
import { ADMIN_ROUTE, CATEGORY_GET_ALL_ROUTE } from '../../utils/consts'

import './CategoryPage.css'


const CategoryCreatePage = () => {

    const navigate  = useNavigate()

    const [ name, setName ] = useState()
    const [ url, setUrl ] = useState()
    const [ whose, setWhose ] = useState()

    const createCategory = () => {
        let data = {
            name, url, whose
        }
        create(data)
            .then(
                data => {
                    // alert(JSON.stringify(data)
                    if (data.name) {
                        alert(`Категория ${data.name} создана`)
                        navigate(CATEGORY_GET_ALL_ROUTE)
                    }
                }),
                error => alert(JSON.stringify(error))
            .catch(error => alert(JSON.stringify(error)))
    }


    return (
        <div 
            className="CategoryPage globalStylePage"
        >
            <div
                // className="CategoryPage_title globalStylePage_title"
            >
                <br />
                <NavLink
                    className="btn btn-dark"
                    to={ADMIN_ROUTE}
                >
                    Админ панель
                </NavLink>

                <br /><br />

                <center> <h3>Создание новой категорий</h3> <br />
                <table>                    
                    <tr>
                        <td>Имя</td>
                        <td>
                            <input 
                                className="inp inp-success" 
                                value={name}
                                onChange={e => setName(e.target.value)}  
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Ссылка</td>
                        <td>
                            <input 
                                className="inp inp-success" 
                                value={url}
                                onChange={e => setUrl(e.target.value)}  
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Чья категория</td>
                        <td>
                            <select 
                                className="inp inp-success"
                                value={whose}
                                onChange={e => setWhose(e.target.value)}
                            >
                                <option value="publication">Публикации</option>
                                <option value="product">Товара</option>
                            </select>
                        </td>
                    </tr>
                </table>
                </center>

                <br />

                <button
                    className="btn btn-success" 
                    onClick={createCategory}
                >
                    Создать
                </button>

                <br /><br />
            </div>
        </div>
    )
}

export default CategoryCreatePage
