//

import { NavLink } from 'react-router-dom'
import logo from '../../../assets/logo.png'

import './Menu.css'
import { INFO_ROUTE, LK_ROUTE, MAIN_ROUTE, PRODUCT_ROUTE, PROMO_ROUTE, PUBLICATION_ROUTE, SUPPORT_ROUTE } from '../../../utils/consts'


 const Menu = () => {

    return (
        <>
            <div className="menu">
                <div className="line-menu logo">
                    <NavLink 
                        className="btn btn-danger home"
                        to={MAIN_ROUTE}
                    >
                        ХуторянинЪ
                    </NavLink>
                </div>
                <nav className="line-menu nav">
                    <NavLink 
                        className="btn btn-outline-dark"
                        to={PUBLICATION_ROUTE}
                    >
                        Публикации
                    </NavLink>
                    <NavLink 
                        className="btn btn-outline-primary"
                        to={PRODUCT_ROUTE}
                    >
                        Товары
                    </NavLink>
                    <NavLink 
                        className="btn btn-outline-violet"
                        to={INFO_ROUTE}
                    >
                        Информация
                    </NavLink>
                    <NavLink 
                        className="btn btn-outline-warning"
                        to={SUPPORT_ROUTE}
                    >
                        Тех.поддержка
                    </NavLink>
                    {/* <NavLink 
                        className="btn btn-outline-success"
                        to={PROMO_ROUTE}
                    >
                        Акции
                    </NavLink> */}             
                    {/* <NavLink 
                        className="btn btn-outline-violet"
                        to={LK_ROUTE}
                    >
                        Л.К.
                    </NavLink> */}
                </nav>
            </div>

            <div className="menu-mobile">
                <div className="topmenu logo">
                    <NavLink 
                        className="btn btn-outline-danger"
                        to={MAIN_ROUTE}
                    >
                        ХуторянинЪ
                    </NavLink>
                </div>  
                <input type="checkbox" id="check" />
                <label htmlFor="check">
                    <i className="fa fa-bars fa-lg" aria-hidden="true" id="btn"></i>
                    <i className="fa fa-times fa-lg" id="cancel"></i>                    
                    <i id="fon"></i>
                </label>
                <div className="sidebar">
                    <header>&nbsp;М&nbsp;Е&nbsp;Н&nbsp;Ю&nbsp;</header>
                    <ul>
                        <li>                  
                            <NavLink 
                                className="btn btn-outline-danger"
                                to={MAIN_ROUTE}
                            >
                                Главная
                            </NavLink>
                        </li>
                        <li>
                            <NavLink 
                                className="btn btn-outline-dark"
                                to={PUBLICATION_ROUTE}
                            >
                                Публикации
                            </NavLink>
                        </li>
                        <li>
                            <NavLink 
                                className="btn btn-outline-primary"
                                to={PRODUCT_ROUTE}
                            >
                                Товары
                            </NavLink>
                        </li>
                        <li>
                            <NavLink 
                                className="btn btn-outline-violet"
                                to={INFO_ROUTE}
                            >
                                Информация
                            </NavLink>
                        </li>
                        <li>
                            <NavLink 
                                className="btn btn-outline-warning"
                                to={SUPPORT_ROUTE}
                            >
                                Тех.поддержка
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink 
                                className="btn btn-outline-success"
                                to={PROMO_ROUTE}
                            >
                                Акции
                            </NavLink>
                        </li> */}
                        {/* <li>        
                            <NavLink 
                                className="btn btn-outline-violet"
                                to={LK_ROUTE}
                            >
                                Л.К.
                            </NavLink>
                        </li> */}
                    </ul>
                </div>
            </div>
        </>
    )
 }

 export default Menu
 