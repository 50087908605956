
import { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

import { create } from '../../http/publicationApi'
import { getAll as getAllUsers } from '../../http/userApi'
import { getAll as getAllCategories } from '../../http/categoryApi'
import { ADMIN_ROUTE, PUBLICATION_GET_ALL_ROUTE } from '../../utils/consts'
import './PublicationPage.css'


const PublicationCreatePage = () => {

    const navigate = useNavigate()

    const [ user_id, setUser_id ] = useState()
    const [ category_id, setCategory_id ] = useState()
    const [ name, setName ] = useState()

    const [ allUsers, setAllUsers ] = useState([])
    const [ allCategories, setAllCategories ] = useState([])

    useEffect(() => {
        getAllUsers().then(data => {
            setAllUsers(data)
            if (data && data[0]) setUser_id(data[0].id)
        })
        getAllCategories().then(data => {
            setAllCategories(data)
            if (data && data[0]) setCategory_id(data[0].id)
        })
    },[])

    const createPublication = () => {
        let data = {
            user_id, category_id, name
        }
        create(data)
            .then(
                data => {
                    // alert(JSON.stringify(data)
                    if (data.name) {
                        alert(`Публикация ${data.name} создана`)
                        navigate(PUBLICATION_GET_ALL_ROUTE)
                    }
                }),
                error => alert(JSON.stringify(error))
            .catch(error => alert(JSON.stringify(error)))
    }


    return (
        <div 
            className="PublicationPage globalStylePage heightBody"
        >
            <div
                // className="PublicationPage_title globalStylePage_title"
            >
                <br />
                <NavLink
                    className="btn btn-dark"
                    to={ADMIN_ROUTE}
                >
                    Админ панель
                </NavLink>

                <br /><br />

                <center> <h3>Создание новой публикации</h3> <br />
                <table> 
                    <tr>
                        <td>Пользователь</td>
                        <td>
                            {allUsers && allUsers[0] 
                            ?
                                <select 
                                    className="inp inp-success"
                                    value={user_id}
                                    onChange={e => setUser_id(e.target.value)}
                                >
                                    {allUsers.map(user => <>
                                        <option value={user.id}>{user.name}</option>
                                    </>)}
                                </select>
                            :
                                "Нет созданных пользователей"
                            }
                        </td>
                    </tr>
                    <tr>
                        <td>Категория</td>
                        <td>
                            {allCategories && allCategories[0] 
                            ?
                                <select 
                                    className="inp inp-success"
                                    value={category_id}
                                    onChange={e => setCategory_id(e.target.value)}
                                >
                                    {allCategories.map(category => <>
                                        <option value={category.id}>{category.name}</option>
                                    </>)}
                                </select>
                            :
                                "Нет созданных категорий"
                            }
                        </td>
                    </tr>                   
                    <tr>
                        <td>Имя</td>
                        <td>
                            <input 
                                className="inp inp-success" 
                                value={name}
                                onChange={e => setName(e.target.value)}  
                            />
                        </td>
                    </tr>
                </table>
                </center>

                <br />

                <button
                    className="btn btn-success" 
                    onClick={createPublication}
                >
                    Создать
                </button>

                <br /><br />
            </div>
        </div>
    )
}

export default PublicationCreatePage
