
import { useParams } from 'react-router-dom'

import NotFound from '../../components/not_found/NotFound'
import UserCreatePage from './UserCreatePage'
import UserGetAllPage from './UserGetAllPage'

import './UserPage.css'


const UserPage = () => {
    
    let { name } = useParams()

    return (
        <div 
            className="UserPage globalStylePage"
        >
            <div
                className="UserPage_title"
            >
                {!name
                ? 
                    <h2 className="globalStylePage_title">Список пользователей</h2>
                :
                name == "create" // for ADMIN
                ?
                    <UserCreatePage />
                :
                name == "get_all" // for ADMIN
                ?
                    <UserGetAllPage />
                :
                    <NotFound />
                }
            </div>
        </div>
    )
}

export default UserPage
