
import { useContext, useState } from 'react'
import { Image } from 'react-bootstrap'

import MyModal from '../../../components/my_bootstrap/my_modal/MyModal'
import { editImage } from '../../../http/publicationDataApi'

import '../PublicationPage.css'


const EditPhotoModal = (props) => {

    const [ file, setFile ] = useState(null)
    const [ fileReader, setFileReader ] = useState(null)


    const selectFile = event => {
        let reader = new FileReader()
        reader.onload = event => {
            setFileReader(event.target.result)
        }
        reader.readAsDataURL(event.target.files[0])
        setFile(event.target.files[0])
    }

    const onClickDownload = async () => {        
        const formData = new FormData()
        // formData.append('publication_id', props?.publication_id)
        formData.append('image', file)
        
        await editImage(props?.publication_data_id, formData)
            .then(
                data => {
                    props?.setImage(data)
                    props?.onHide()
                    setFileReader(null)
                },
                err => alert(err.message)
            )
            .catch(error => alert(error))
        // props?.onHide()
    }


    return (
        <MyModal
            show={props?.show}
        >
            <header>Загрузить файл!</header>

            <main>
                <input 
                    type="file"
                    className='inp inp-success'
                    onChange={selectFile}
                    accept="image/*,image/jpeg"
                />

                <br /><br />

                {fileReader && <Image src={fileReader} className="fileReaderImage" />}

            </main>

            <footer>
                <button className="btn btn-primary" onClick={onClickDownload}>Загрузить</button>
                &nbsp;
                <button className="btn btn-outline-danger" onClick={() => {props?.onHide(); setFileReader(null)}}>Закрыть</button>
            </footer>
        </MyModal>
    )
}

export default EditPhotoModal