
import { Routes, Route } from 'react-router-dom'

import MainPage from '../pages/main/MainPage'
import PublicationPage from '../pages/publication/PublicationPage'
import ProductPage from '../pages/product/ProductPage'
import CategoryPage from '../pages/category/CategoryPage'
import PromoPage from '../pages/promo/PromoPage'
import SupportPage from '../pages/support/SupportPage'
import InfoPage from '../pages/info/InfoPage'
import LkPage from '../pages/lk/LkPage'
import UserPage from '../pages/user/UserPage'

import { 
    ABOUT_ME_ROUTE, CATEGORY_ROUTE, INFO_ROUTE, LK_ROUTE, PRODUCT_ROUTE, 
    PROMO_ROUTE, PUBLICATION_ROUTE, SUPPORT_ROUTE, USER_ROUTE 
} from '../utils/consts'


const AppRouter = () => {
    
    return (
        <Routes>
            <Route path={"/"} element={<MainPage/>} status={200} exact />

            <Route path={USER_ROUTE} element={<UserPage/>} status={200} />
            <Route path={USER_ROUTE + "/:name"} element={<UserPage/>} status={200} />

            <Route path={CATEGORY_ROUTE} element={<CategoryPage/>} status={200} />
            <Route path={CATEGORY_ROUTE + "/:name"} element={<CategoryPage/>} status={200} />
            {/* <Route path={CATEGORY_ROUTE + "/:name/:publication_name"} element={<CategoryPage/>} status={200} /> */}

            <Route path={PUBLICATION_ROUTE} element={<PublicationPage/>} status={200} />
            <Route path={PUBLICATION_ROUTE + "/:name"} element={<PublicationPage/>} status={200} />

            <Route path={PRODUCT_ROUTE} element={<ProductPage/>} status={200} />
            <Route path={PRODUCT_ROUTE + "/:name"} element={<ProductPage/>} status={200} />

            <Route path={SUPPORT_ROUTE} element={<SupportPage/>} status={200} />

            <Route path={INFO_ROUTE} element={<InfoPage/>} status={200} />
            <Route path={ABOUT_ME_ROUTE} element={<InfoPage/>} status={200} />

            <Route path={PROMO_ROUTE} element={<PromoPage/>} status={200} />

            <Route path={LK_ROUTE} element={<LkPage/>} status={200} />
            
            <Route path={"/:name/:publication_name"} element={<PublicationPage/>} status={200} />
            <Route path={"/:name/:publication_id/:publication_name"} element={<PublicationPage/>} status={200} />
        </Routes>
    )
}

export default AppRouter
