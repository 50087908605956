

import './MyModal.css'


const MyModal = (props) => {

    if ( ! props.show )
        return (<></>) 

    return (
        <>
            <div
                className='MyModal_modal'
            >

                {props?.children}

            </div>

            <div className='MyModal_backmodal' />
        </>
    )
}

export default MyModal