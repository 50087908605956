//

import './NotFound.css'


const NotFound = (props) => {

    return (
        <div className={props.minimal ? "Repair" : "Repair Repair_maxHeight"}>
            <div className="Repair_box">
                <div className="Repair_boxTitle">
                    <h1>Страница не найдена!</h1>
                </div>
                <div className="Repair_boxBody">
                    <h3 style={{color:"green"}}>Проверьте правильность ссылки</h3>
                    <h3>{window.location.href}</h3>
                </div>
            </div>
        </div>
    )
}

export default NotFound
