
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { edit, deleteImage } from '../../../http/publicationDataApi'
import EditPhotoModal from './EditPhotoModal'

import '../PublicationPage.css'


const PublicationDataEdit = (props) => {

    // const navigate  = useNavigate()

    const [ publication_id ] = useState(props.publication.id)
    // const [ number, setNumber ] = useState(props.publicationData.number)
    const [ image, setImage ] = useState(props.publicationData.image)
    const [ data, setData ] = useState(props.publicationData.data)

    const [ textareaColor, setTextareaColor ] = useState("black")
    const [ textareaSize, setTextareaSize ] = useState(0)

    const [ editPhotoModalVisible, setEditPhotoModalVisible ] = useState(false)

    const savePublicationData = () => {
        if (textareaSize > 8000) {
            alert("Слишком много буковков (:")
            return
        }
        let body = {
            publication_id, /*number,*/ image, data
        }
        edit(props.publicationData.id, body)
            .then(
                dataSet => {
                    // alert(JSON.stringify(data)
                    if (dataSet && dataSet[0]) {
                        alert(`Данные к публикации сохранены`)
                        props.back()
                    }
                }),
                error => alert(JSON.stringify(error))
            .catch(error => alert(JSON.stringify(error)))
    }

    const setTextareaData = (text) => {
        setData(text)
        let size = new Blob([text]).size
        setTextareaSize(size)
        if (size > 8000) {
            // alert("больше 8000")
            setTextareaColor("red")
        }else {
            if (textareaColor != "black") setTextareaColor("black")
        }
    }

    const onClickDeletePhoto = () => {
        deleteImage(props?.publicationData.id, image)
            .then(
                dataSet => {
                    // alert(JSON.stringify(data)
                    if (dataSet) {
                        alert(`Изображение удалено`)
                        // props.back()
                        setImage("")
                    }
                }),
                error => alert(JSON.stringify(error))
            .catch(error => alert(JSON.stringify(error)))
    }


    return (
        <div>
            <div>
                <button
                    className="btn btn-warning"
                    onClick={() => props.back()}
                >
                    Назад
                </button>

                <br /><br />

                <center> 
                    <h3>Редактирование данных к публикации&nbsp;"<span style={{color:"blueviolet"}}>{props.publication.name}</span>"</h3> 
                    <br />   
                
                    <label>Изображение</label>
                    <br />                       
                    <input 
                        className="inp inp-success" 
                        value={image}
                        size={"35"}
                        disabled
                    />
                    &nbsp;
                    <button
                        className="btn btn-primary" 
                        onClick={() => setEditPhotoModalVisible(true)}
                    >
                        {image ? "Изменить" : "Добавить"}
                    </button>
                    {image && <>
                     &nbsp;
                    <button
                        className="btn btn-danger" 
                        onClick={onClickDeletePhoto}
                    >
                        удалить
                    </button>
                    </>}
                    <br /><br />
                            
                    <label>Текстовые данные</label>
                    <br />   
                    <textarea 
                        className="inp inp-success" 
                        style={{color: textareaColor}}
                        value={data} cols="45" rows="20"
                        onChange={e => setTextareaData(e.target.value)}  
                    />
                </center>

                <span style={{color: textareaColor}}>{textareaSize}&nbsp;символов из 8000</span>

                {textareaSize > 8000 && " перенесите текст в новую запись"}
                
                <br /><br />

                <button
                    className="btn btn-success" 
                    onClick={savePublicationData}
                >
                    Сохранить
                </button>

                <br /><br />
            </div>
            
            <EditPhotoModal 
                show={editPhotoModalVisible} 
                onHide={() => setEditPhotoModalVisible(false)} 
                publication_id={publication_id} 
                publication_data_id={props.publicationData.id} 
                setImage={setImage}
            />
                
        </div>
    )
}

export default PublicationDataEdit
