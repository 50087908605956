


import '../PublicationPage.css'


const PublicationDataView = (props) => {

    return (
        <>
            <button
                className="btn btn-outline-violet" 
                onClick={() => props.back()}
            >
                Список данных
            </button>

            <br /><br />
                         
            <center> <h3>Публикация&nbsp;"<span style={{color:"blueviolet"}}>{props.publication.name}</span>"</h3> <br />     
            <h4>Данные номер&nbsp;"<span style={{color:"green"}}>{props.publicationData.number}</span>"</h4> <br /> 
            <table style={{wordWrap: "break-word"}}>
                <tbody>
                    {/* <tr>
                        <td>#</td>
                        <td>{props.publicationData.id}</td>
                    </tr> */}
                    {/* <tr>
                        <td>Номер публикации</td>
                        <td>{props.publicationData.publication_id}</td>
                    </tr> */}
                    {/* <tr>
                        <td>Номер по счёту</td>
                        <td>{props.publicationData.number}</td>
                    </tr> */}
                    <tr>
                        <td>Ссылка на изображение</td>
                        <td>{props.publicationData.image}</td>
                    </tr>
                    <tr>
                        <td>Описание</td>
                        <td>{props.publicationData.data}</td>
                    </tr>
                </tbody>
            </table>
            </center>                
            <br />
        </>
    )
}

export default PublicationDataView
