
import { NavLink } from 'react-router-dom'
import './MainPage.css'
import './contain.css'
import { SUPPORT_ROUTE } from '../../utils/consts'


const MainPage = () => {

    return (
        <div 
            className="MainPage"
        > 
            <div 
                className="globalStylePage_title"
            >
                <h1
                    style={{color:"blue"}}
                >
                    Добро пожаловать!
                </h1>
                <p>Это сайт Хуторянина.</p>
            </div>



            <div> 
                
                <br/>
                <br/>
                <h2>Публикации.</h2>                
                <br/>
                <br/>

                <div>
                    <p><NavLink className="btn btn-dark" to="/publication">Публикации</NavLink> различных самоделок.</p>
                    <p>Например разработал я макетную плату для ESP8684-WROOM-01C.</p>
                    <p>Для возможности повторения самоделки в разделе Публикации</p>
                    <p>можно будет найти страницу с подробным описанием платы с изображениями.</p>
                    <p>Это удобно так же для себя самого, что бы не запоминать где что, на каких выводах подключено.</p>
                    <p>Всегда можно открыть и уточнить. Я понимаю что таких подобных сайтов море,</p>
                    <p>но я хочу свои поделки оставлять здесь.</p>
                    <p>Так же планирую добавить возможность публиковать свои поделки всем желающим,</p>
                    <p>о моих планах можете узнать в разделе <NavLink className="btn btn-violet" to="/info">Информация</NavLink></p>
                </div>

                <br/>
                <br/>

            </div>

            <hr />

            
            <div> 
                
                <br/>
                <br/>
                <h2>Товары.</h2>                
                <br/>
                <br/>

                <div>
                    <p>Планирую размещать <NavLink className="btn btn-primary" to="/product">Товары</NavLink> своих поделок.</p>
                    <p>Ранее на этом сайте были мои самоделки из дерева и металла.</p>
                    <p>Сейчас же в основном я занимаюсь программированием и как хобби</p>
                    <p>программированием микроконтроллеров (далее МК).</p>
                    <p>Разрабатываю свои макетные платы для различных МК.</p>
                    <p>Но так же у меня остались в наличие платы разработки&nbsp;
                        <a 
                            className="btn btn-outline-danger" 
                            href="https://www.youtube.com/@AlexGyverShow" 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            Алекса Гайвера
                        </a>
                    </p>
                    <p>
                        Это плата&nbsp;
                        <a 
                            className="btn btn-outline-dark" 
                            href="https://github.com/AlexGyver/GyverControl" 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            GyverControl
                        </a>
                        &nbsp;- кому интересно будет, сможете приобрести.</p>
                </div>

                <br/>
                <br/>

            </div>

            <hr />



            <br/>
            <br/>

            {/* <div className="benefit">
                <h2 className="benefit_text_top">Что можно приобрести?!</h2>
                
                <p style="text-align: center;">Всем авторизованным пользователям доступна скидка {{ discount }}%.</p>
                
                <br/><br/>
                <NavLink to="{% url 'middle_knife' %}" >
                    <div className="benefit_img1">
                        <img src="{% static 'img/middle_knife(rectangle).png'%}" alt="Ножи"/>
                        <h3 className="benefit_img_text">Нож "Друг" за <span className="g">1000р</span>&nbsp;<NavLink to="{% url 'middle_knife' %}" className="btn btn-outline-danger" id="btn-buy-1">Смотреть</NavLink></h3>
                    </div>
                </NavLink>

                <NavLink to="{% url 'high_knife_bee' %}?color=red" >
                    <div className="benefit_img2">
                        <img src="{% static 'img/high_knife_bee_red(rectangle).png'%}" alt="high_knife_bee_red"/>
                        <h3 className="benefit_img_text">Нож "Красный Шмель" за <span className="g">2000р</span>&nbsp;<NavLink to="{% url 'high_knife_bee' %}?color=red" className="btn btn-outline-danger" id="btn-buy-2">Смотреть</NavLink></h3>
                    </div>
                </NavLink>

                <NavLink to="{% url 'high_spoon' %}" >
                    <div className="benefit_img3">
                        <img src="{% static 'img/sp1.png'%}" alt="Ложка"/>
                        <h3 className="benefit_img_text">Ложка "Царица" за <span className="g">300р</span>&nbsp;<NavLink to="{% url 'high_spoon' %}" className="btn btn-outline-danger" id="btn-buy-3">Смотреть</NavLink></h3>
                    </div>
                </NavLink>

                <h3 className="benefit_text_bottom"><NavLink to={PRODUCT_ROUTE} className="btn btn-outline-primary">Посмотреть все товары</NavLink></h3>
            </div> */}

            <div > 
                
                <h2>Вопросы и предложения.</h2>
                <br/>

                
                <br/>
                <br/>

                <div>
                    <p>Все возможные вопросы и самые разнообразные предложения</p>
                    <p>прошу направлять в <NavLink className="btn btn-warning" to={SUPPORT_ROUTE}>Тех.поддержку</NavLink></p>
                </div>

                <br/>
                <br/>

            </div>
            
        </div>
    )
}

export default MainPage

