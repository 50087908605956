
import { useParams } from 'react-router-dom'

import NotFound from '../../components/not_found/NotFound'
import CategoryCreatePage from './CategoryCreatePage'
import CategoryGetAllPage from './CategoryGetAllPage'

import './CategoryPage.css'


const CategoryPage = () => {
    
    let { name } = useParams()

    return (
        <div 
            className="CategoryPage globalStylePage"
        >
            <div
                className="CategoryPage_title"
            >
                {!name
                ? 
                    <h2 className="globalStylePage_title">Список категорий</h2>
                :
                name == "create" // for ADMIN
                ?
                    <CategoryCreatePage />
                :
                name == "get_all" // for ADMIN
                ?
                    <CategoryGetAllPage />
                :
                    <NotFound />
                }
            </div>
        </div>
    )
}

export default CategoryPage
