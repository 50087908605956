
import { useNavigate } from 'react-router-dom'

import { ADMIN_ROUTE } from '../../utils/consts'

import './Footer.css'


const Footer = () => {
    
    const navigate  = useNavigate()

    const onClickSpan = () => {
        navigate(ADMIN_ROUTE) 
    }

    return (
        <div className="Footer">
            <div className="Footer_brand">
                <span onClick={() => onClickSpan()}>&copy;</span>&nbsp;
                ХуторянинЪ 2017-{(new Date()).getFullYear()}
            </div>
            {/* Footer */}
        </div>
    )
}

export default Footer
