
import { useNavigate } from 'react-router-dom'

import logo from '../../../assets/logo.png'
import { MAIN_ROUTE } from '../../../utils/consts'

import './Logo.css'


const Logo = () => {

    const navigate = useNavigate()

    const onClickLogo = () => {
        navigate(MAIN_ROUTE)
    }

    return (
        <div 
            className="Logo"
            onClick={onClickLogo}
        >
            <img 
                src={logo} 
                className="Logo_img" 
                alt="Логотип Руна Йера" 
            />
        </div>
    )
}

export default Logo
