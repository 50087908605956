
import { useState, useEffect } from 'react'

import { getAllWherePublicationId, deleteOne } from '../../../http/publicationDataApi'
import PublicationDataCreate from './PublicationDataCreate'

import '../PublicationPage.css'
import PublicationDataEdit from './PublicationDataEdit'
import PublicationDataView from './PublicationDataView'


const PublicationData = (props) => {

    const [ publicationData, setPublicationData ] = useState([])
    const [ createPublicationData, setCreatePublicationData ] = useState(false)
    const [ viewPublicationData, setViewPublicationData ] = useState(null)
    const [ editPublicationData, setEditPublicationData ] = useState(null)
    
    useEffect(() => {
        if (createPublicationData == false)
            if (props && props.publication) 
                getPublicationData(props.publication.id)
    },[createPublicationData])

    const getPublicationData = (id) => {
        getAllWherePublicationId(id).then(data => setPublicationData(data))
    }

    const onClickDeletePublicationData = (publication) => {
        let yes = confirm(`Вы уверены что хотите удалить часть публикации номер ${publication.number}?`)
        if (yes) deleteOne(publication.id).then(data => {
            if (data) {
                getPublicationData(props.publication.id)
                alert("Часть публикации удалена")
            }
        })
    }


    return (
        <div className="PublicationData heightBody">
            <div>
                <button
                    className="btn btn-outline-dark" 
                    onClick={() => props.setPublication(null)}
                >
                    Список публикаций
                </button>

                <br /><br />

                {createPublicationData == true 
                ? 
                    <PublicationDataCreate 
                        publication={props.publication} 
                        back={() => setCreatePublicationData(false)} />
                : 
                viewPublicationData
                ?
                    <PublicationDataView 
                        publication={props.publication} 
                        publicationData={viewPublicationData} 
                        back={() => setViewPublicationData(null)} />
                : 
                editPublicationData
                ?
                    <PublicationDataEdit 
                        publication={props.publication} 
                        publicationData={editPublicationData} 
                        back={() => {setEditPublicationData(null); getPublicationData(props.publication.id)}} />
                :
                <>                
                <button
                    className="btn btn-outline-success" 
                    onClick={() => setCreatePublicationData(true)}
                >
                    Добавить новую
                </button>

                <br /><br />

                <center> <h3>Публикация&nbsp;"<span style={{color:"blueviolet"}}>{props.publication.name}</span>"</h3> <br />   
                <table>
                    <thead>
                    <tr>
                        {/* <th>#</th> */}
                        {/* <th>Id публикации</th> */}
                        {/* <th>Номер</th> */}
                        <th>Картинка</th>
                        <th>Данные</th>
                        {/* <th>Дата создания</th> */}
                        <th>Действия</th>
                    </tr> 
                    </thead>  
                    <tbody>
                    {publicationData && publicationData[0] &&
                    publicationData.map((publication, idx) =>                  
                    <tr key={"keyPublicationData" + idx}>
                        {/* <td>{publication.id}</td> */}
                        {/* <td>{publication.publication_id}</td> */}
                        {/* <td>{publication.number}</td> */}
                        <td>{publication.image}</td>
                        <td>{publication.data && publication.data.substr(0, 15) + "..."}</td>
                        {/* <td>
                            {publication.createdAt.split("T")[0].split("-")[2]}
                            .
                            {publication.createdAt.split("T")[0].split("-")[1]}
                            .
                            {publication.createdAt.split("T")[0].split("-")[0]}
                        </td> */}
                        <td>
                            <button
                                className="btn btn-primary" 
                                onClick={() => setViewPublicationData(publication)}
                            >
                                Посмотреть
                            </button> <br />
                            <button
                                className="btn btn-warning" 
                                onClick={() => setEditPublicationData(publication)}
                            >
                                Изменить
                            </button> <br />
                            <button
                                className="btn btn-danger" 
                                onClick={() => onClickDeletePublicationData(publication)}
                            >
                                Удалить
                            </button>
                        </td>
                    </tr>
                    )}     
                    </tbody>               
                </table>
                </center>
                </>
                }

            </div>
        </div>
    )
}

export default PublicationData
