
import { NavLink } from 'react-router-dom'

import { 
    PRODUCT_CREATE_ROUTE, PRODUCT_GET_ALL_ROUTE,
    PUBLICATION_CREATE_ROUTE, PUBLICATION_GET_ALL_ROUTE,
    USER_CREATE_ROUTE, USER_GET_ALL_ROUTE,
    CATEGORY_CREATE_ROUTE, CATEGORY_GET_ALL_ROUTE,     
} from '../../utils/consts'

import './AdminPage.css'


const AdminPage = () => {
    
    return (
        <div 
            className="AdminPage globalStylePage"
        >
            <div
                className="AdminPage_title"
            >
                <h1 className="AdminPage_title globalStylePage_title">Администрирование</h1>

                <br />

                <div>
                    <NavLink
                        className="btn btn-violet"
                        to={USER_CREATE_ROUTE}
                    >
                        Новый пользов.
                    </NavLink>
                    &nbsp;
                    <NavLink
                        className="btn btn-outline-violet"
                        to={USER_GET_ALL_ROUTE}
                    >
                        Список пользов.
                    </NavLink>
                </div>

                <br />
                
                <div>
                    <NavLink
                        className="btn btn-success"
                        to={CATEGORY_CREATE_ROUTE}
                    >
                        Новая категория
                    </NavLink>
                    &nbsp;
                    <NavLink
                        className="btn btn-outline-success"
                        to={CATEGORY_GET_ALL_ROUTE}
                    >
                        Список категорий
                    </NavLink>
                </div>

                <br />

                <div>
                    <NavLink
                        className="btn btn-dark"
                        to={PUBLICATION_CREATE_ROUTE}
                    >
                        Новая публикация
                    </NavLink>
                    &nbsp;
                    <NavLink
                        className="btn btn-outline-dark"
                        to={PUBLICATION_GET_ALL_ROUTE}
                    >
                        Список публикаций
                    </NavLink>
                </div>

                <br />

                <div>
                    <NavLink
                        className="btn btn-primary"
                        to={PRODUCT_CREATE_ROUTE}
                    >
                        Новый товар
                    </NavLink>
                    &nbsp;
                    <NavLink
                        className="btn btn-outline-primary"
                        to={PRODUCT_GET_ALL_ROUTE}
                    >
                        Список товаров
                    </NavLink>
                </div>

                <br />

            </div>
        </div>
    )
}

export default AdminPage
