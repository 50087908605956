
import { useState } from 'react'
import { NavLink } from 'react-router-dom'

import { PRODUCT_ROUTE, PROMO_ROUTE, SUPPORT_ROUTE } from '../../utils/consts'

import './InfoPage.css'


const InfoPage = () => {

    const [ aboutMe, setAboutMe ] = useState(false)

    const onClickButtonAboutMe = () => {
        setAboutMe(!aboutMe)
    }

    return (
        <div 
            className="InfoPage"
        > 
            <div>
                <h1 
                    className="globalStylePage_title" 
                    style={{color:"blueviolet"}}>
                        Раздел Информация
                </h1>
                <div className="InfoPage_title">
                    <p>В данном разделе вы можете ознакомиться с информацией о сайте.</p>
                    <p>О существующих разделах сайта, а так же о планах развития сайта.</p>
                    <p>А так же можете познакомиться с Хуторянином - блогером, программистом.</p>
                    <p>Вопросы и предложения прошу направлять в <NavLink className="btn btn-warning" to={SUPPORT_ROUTE}>Тех.поддержку</NavLink></p>
                    <p>Так же туда направляйте все возможные варианты сотрудничества.</p>
                    <p>Всем отвечу обязательно, главное оставляйте для ответа корректный e-mail.</p>
                </div>
            </div>
            
            <hr />
            
            <div>
                
                <h2 className="globalStylePage_title">Планы развития</h2>

                <div className="InfoPage_title">
                    <p>Пока тезисно набросаю идеи:</p>
                    <br />
                    <ul>
                        <li>Добавить раздел Публикации (публикации самоделок с различными МК)</li>
                        <li>Добавить товаров (например: плата Хуторино, плата GyverControl, макетка для QFN88)</li>
                        <li>Дополнить раздел Информация</li>
                        <li>Добавить раздел Авторизация с Личным кабинетом</li>
                        <li>Автоматизировать раздел Публикаций, для добавления страниц авторизованными пользователями</li>
                    </ul>
                </div>

            </div>
            
            <hr />
            
            <div>                
                <h2 className="globalStylePage_title">О Хуторянине</h2>
            </div>

            <div className="InfoPage_imageBox">

                <div className="InfoPage_title">
                    <p>Меня зовут Огънеяръ, можно просто - Яр.</p>
                    <p>Это моё фото от 2021го года.</p>
                </div>

                <div className="InfoPage_imageBox_image"> 
                    <h2 className="InfoPage_imageBox_image_caption">Азъ есмь Огънеяръ!</h2>
                    <img 
                        className="InfoPage_imageBox_image_img75" 
                        src="images/ya.jpg" 
                        alt="моё фото" 
                    />	
                    <h2 className="InfoPage_imageBox_image_caption-two">Азъ есмь Огънеяръ!</h2>
                </div>
                
                <div
                    className="InfoPage_imageBox_aboutBox"
                >
                    <div 
                        className="InfoPage_imageBox_aboutBox_aboutBtn"
                        id="about"
                        onClick={() => onClickButtonAboutMe()}
                    >
                        <h4>Подробнее...</h4>
                    </div>
                    
                    {aboutMe &&
                    <div 
                        className="InfoPage_imageBox_aboutBox_aboutText"
                    >
                        <br/><br/>
                        <p className="centerText">Здравствуйте все! Я блогер, ютубер ХуторянинЪ. </p>
                        <br/>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;Программист по образованию (по специальности), а уже кузнец по случайности, немного столяр, математик и даже чу-чуть певец. Славянин, наречён Весътой именем Огънеяръ. Друзья и близкие так и зовут - Огнеяр или просто Яр, имя данное родителями Михаил переменил официально через ЗАГС. Я сын Валерия и Ольги (Звенирады), внук Сергея и Томары, а так же Леонида и Татьяны. Слава Роду! Слава наша - Богам и Предкам!</p>
                        <br/>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;По специальности устроился лишь в ноябре 2022 года - до это программировал так, для себя, как хобби, теперь хобби тоже программирование но в сфере микроконтроллеров (плюс разработка плат, пайка компонентов). А вообще трудился я во многих сферах, был охранником, грущиком, кладовщиком, зав.складом, мастером по установке спутникового оборудования, инженером-ремонтником холодильного оборудования и инженером пекарни. С февраля 2017 года веду свой блог на ютуб-канале <a className="btn btn-outline-danger" href="https://www.youtube.com/@hutoryanin" target="_blank" rel="noopener noreferrer">ХуторянинЪ</a>. Запуская этот сайт оформился ещё и как самозанятый, что бы официально торговать собственными изделиями ручного производства и продуктами умственного труда.</p>
                        <br/> 
                        {/* <p>&nbsp;&nbsp;&nbsp;&nbsp;Кстати все мои видео с ютуб-канала можно посмотреть здесь на сайте, во вкладке <NavLink className="btn btn-outline-dark" to="/public/1">Публикации</NavLink> нажав тут или в меню на верху. Жду Ваш одобряющий лайк и, когда перейдёте на ютуб, Ваш комментарий под видео, это способствует продвижению канала, заранее благодарю. Обязательно подпишитесь, если ещё не подписанны и жмите колокольчик чтобы не пропускать мои новые видео.</p>
                        <br/> */}
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;Этот сайт я сделал сам. Обновляю постепенно, добовляю что-либо, приукрашаю. Пока что ничего особого в нём нет, но это временно, надеюсь на Вашы советы, подсказки. Если есть идеи оформления сайта или увидели какой-нибудь недочёт, просьба написать в <NavLink className="btn btn-outline-warning" to={SUPPORT_ROUTE}>тех.поддержку</NavLink></p>
                        <br/>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;Немного технической информации касаемой сайта (если кому интересно): это мой третий сайт, изначально он был написан на python 3, фреймворк django, css стили изначально брал от bootstrap'а, хостинг на heroku. Потом я его переписал на ReactJS потому что увлёкся JavaScript'ом и NodeJS. Первый свой сайт писал сам, без фреймворка, на php, css'ки тоже сам прописывал и знаете что, устал, это просто жесть. Сейчас этот сайт не работает, я его отключил, поэтому его глянуть не получится.&nbsp;
                            {/* <a className="btn btn-outline-dark" href="https://prizmarket.ru" target="_blank" rel="noopener noreferrer">Вот он</a> посмотрите. */}        
                        Но вот второй сайт это практически его копия, только расцветку сменил, <a className="btn btn-outline-danger" href="https://7yanika-rostov.ru" target="_blank" rel="noopener noreferrer">вот он</a> посмотрите.</p>
                        <br/>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;В работе сайтов использую телеграм-ботов, они очень помогают в решении разных задач. На первом сайте использовалась вообще "целая армия" ботов, каждый сделан под определённую задачу.</p>
                        <br/>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;Мне было интересно усвоить новый язык программирования, вот я и стал изучать python. А так, я коснулся даже программирования приложений под android, поработал немного с языком java, но к нему я наверное ещё вернусь, когда решу в каком виде должно быть приложение для Хуторянина.</p>
                        <br/>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;Не совсем-то "немного тех. информации" получилось, увлёкся. ;)</p>
                        <br/>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;Периодически кую... или поковываю... Ковать интересно, но быстро устает рука молотком махать, с пневмо-молотом было бы по веселей (или гидро-молотом, или электро, это не так важно). Менее меня увлекает столярка, обтачивать деталь нудно как-то, но как же душа радуется когда процесс подходит к концу и видишь результат своих стараний.</p>
                        <br/>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;Иногда точу на деревообрабатывающем станке. Чаще всего это ложки для мёда, иногда скалку, подсвечник, дверные ручки и ножки для тумбочки. В общем любые круглые предметы могу на станке сделать. Станок самодельный, достался мне вместе с поддворьем от предыдущих хозяев. Хороший станок.</p>
                        <br/>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;На этом сайте решил сделать интернет магазин, где предлагаю товары сделанные своими руками и умом. Такие как ножи, ложки, скалки, подсвечники, сайты, боты и платы Хуторино (аналогия с Arduino). Может и странное сочетание, но так уж вышло, так уж сложилась судьба. Я думаю не спроста мне на дипломную работу в институте выпало сделать именно интернет-магазин. Правда название у диплома было жёсткое, я еле понял что имеется ввиду - "Распределительная система управления формированием заказов и их исполнением".</p>
                        <br/>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;Заходите по ссылке <NavLink className="btn btn-outline-primary" to={PRODUCT_ROUTE}>Товары</NavLink> здесь или в верхнем меню, заказывайте понравившийся товар или услугу, жду Вас.</p>
                        <br/>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;Периодически провожу различные <NavLink className="btn btn-outline-success" to={PROMO_ROUTE}>Акции</NavLink> следите за ними, получайте разные подарки, будь то ложки, скалки, ножи или скидки на товары.</p>
                        <br/>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;Благодарю всех кто дочитал до этих строк, прошу, по учавствуйте в создании этого сайта, мне нужны Ваши идеи, подсказки. Жду от Вас советов касаемых улучшения сайта, буду рад разным предложениям по видеосъёмке, может есть идеи для моих будущих видео-постов? Пишите в <NavLink className="btn btn-outline-warning" to={SUPPORT_ROUTE}>тех.поддержку</NavLink></p>
                        <br/>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;Всем удачи! Пока.</p>
                    </div>
                    }
                        
                </div>

            </div>
            
            <br />
            <br />
            <br />
            <br />
        </div>
    )
}

export default InfoPage
